<header
  class="z-50 flex-shrink-0 top-0 sticky shadow-header border-b-2  border-outline-variant w-[100vw] min-w-[510px] px-8">
  <div class="flex flex-row justify-between items-center h-[64px] ">

    <button (click)="navigateToHome()">
      <mat-icon svgIcon="ic_sbox_brackets" class="mt-1 w-[44px]"></mat-icon>
    </button>

    <div class="flex flex-row items-center justify-center mr-[24px] gap-4">

      <button mat-button (click)="navigateToConsole()" [id]="'goToConsoleButton'">Go to
        Console</button>

      <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="" [id]="'accountCircleButton'">
        <mat-icon class="w-6 h-6 material-symbols-rounded">
          account_circle</mat-icon>
      </button>
    </div>
    <mat-menu class="min-w-[337px] border border-outline-variant  mt-3 shadow-md shadow-border-grey pb-0 "
      #accountMenu="matMenu" xPosition="before">
      <ng-template matMenuContent>
        <div class="flex flex-row items-start ">
          <div class="py-4 px-4">
            <ngx-avatars bgColor="var(--sys-secondary-container)" fgColor="var(--sys-on-secondary-container)" size="56"
              name="{{ (user$ | async)?.firstName[0] | titlecase }}">
            </ngx-avatars>
          </div>
          <div class="flex flex-col m-0 justify-between mt-5">
            <span class="mat-title-medium text-on-surface">{{(user$ | async)?.firstName + " " + (user$ |
              async)?.lastName}}</span>
            <span class="mat-body-medium text-on-surface-variant mt-2">{{(user$ | async)?.email}}</span>
          </div>
        </div>
        <mat-divider class="text-outline-variant"></mat-divider>

        <button (click)="changeTheme()" mat-menu-item class=" flex">
          <div class="flex items-center justify-between">

            <div class="flex justify-start items-center gap-x-2 ">

              <mat-icon class="material-symbols-rounded text-on-surface mr-2 text-menu-item-icon" matPrefix>dark_mode
              </mat-icon>
              <span class="mat-title-small text-on-surface">Dark Theme</span>
            </div>

            <div>
              <mat-slide-toggle [(ngModel)]="darkModeEnabled"></mat-slide-toggle>
            </div>
          </div>

        </button>
        <mat-divider class="border-outline-variant"></mat-divider>

        <button mat-menu-item (click)="logout()" class=" flex">
          <mat-icon class="material-symbols-rounded text-on-surface mr-2" matPrefix>logout
          </mat-icon>
          <span class=" text-on-surface mat-title-small">Sign Out</span>
        </button>
      </ng-template>
    </mat-menu>
  </div>

</header>