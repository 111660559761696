<div class="flex flex-col items-center my-6 ">
  <div class="flex flex-col w-[610px]">

    <div class=" flex mb-4">
      <div class="flex gap-1 items-center justify-center">
        <p class=" text-on-surface cursor-pointer  mat-label-large" (click)="goBack()">
          My Account</p>
        <mat-icon class="material-symbols-rounded text-on-surface-variant">chevron_right</mat-icon>
        <p class=" text-on-surface-variant mat-label-large">Personal details</p>
      </div>
    </div>

    <div class="flex flex-col gap-y-2 mt-[8px]">
      <span class="text-on-surface mat-title-large">Personal Details</span>
      <span class=" text-on-surface-variant mat-body-medium">Update your personal details. Your name is visible across
        all workspaces you are part of.</span>
    </div>
    <form [formGroup]="detailsForm" class="mt-10">
      <mat-form-field appearance="outline" class="w-[600px]">
        <mat-label>First Name</mat-label>
        <input required matInput formControlName="firstName" type="text" placeholder="Aarav">
        <mat-error>
          <ng-container *ngIf="
                      detailsForm.controls['firstName'].errors &&
                      (detailsForm.controls['firstName'].dirty || detailsForm.controls['firstName'].touched)
                    ">
            <ng-container *ngFor="let error of detailsForm.controls['firstName'].errors | keyvalue">
              <ng-container *ngIf="error.key">
                <span>{{ error.value['message'] }}</span>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-[600px]  mt-3">
        <mat-label>Last Name</mat-label>
        <input required matInput formControlName="lastName" type="text" placeholder="Sharma">
        <mat-error>
          <ng-container *ngIf="
                        detailsForm.controls['lastName'].errors &&
                        (detailsForm.controls['lastName'].dirty || detailsForm.controls['lastName'].touched)
                      ">
            <ng-container *ngFor="let error of detailsForm.controls['lastName'].errors | keyvalue">
              <ng-container *ngIf="error.key">
                <span>{{ error.value['message'] }}</span>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-error>
      </mat-form-field>
      <div class="flex flex-row gap-x-2 w-[600px] mt-3">
        <mat-form-field appearance="outline" class="w-[94px]">
          <mat-select class="flex w-[94px]  overflow-hidden" [(value)]="selectedCountry"
            (selectionChange)="changePhoneNumber()" panelWidth="400px">
            <mat-select-trigger>
              <img *ngIf="selectedCountry" matPrefix src="{{selectedCountry.flag}}" class="h-5 w-7 ml-4 mr-[8px]" />
            </mat-select-trigger>
            <mat-option *ngFor=" let c of countryList" [value]="c">
              <div class=" flex">
                <img src="{{c.flag}}" alt="flag" class="h-5 w-7 mr-3" />
                <span>{{c.name}}</span>
              </div>
            </mat-option>
          </mat-select>
          <mat-icon class="py-2 material-symbols-rounded" matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-[498px] bg-surface-container-lowest" floatLabel="always">
          <mat-label>Phone (optional)</mat-label>
          <p matTextPrefix class="text-on-surface mr-[12px]" *ngIf="selectedCountry">
            +{{selectedCountry.numeric_code}}</p>
          <input matInput formControlName="phone" type="text" placeholder="9876543210">
          <mat-error>
            <ng-container *ngIf="
                      detailsForm.controls['phone'].errors &&
                      (detailsForm.controls['phone'].dirty || detailsForm.controls['phone'].touched)
                    ">
              <ng-container *ngFor="let error of detailsForm.controls['phone'].errors | keyvalue">
                <ng-container *ngIf="error.key">
                  <span>{{ error.value['message'] }}</span>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" class="w-[600px] mt-3 ">
        <mat-label>Business name</mat-label>
        <input required matInput formControlName="business" type="text" placeholder="Acme Inc.">
        <mat-error>
          <ng-container *ngIf="
                        detailsForm.controls['business'].errors &&
                        (detailsForm.controls['business'].dirty || detailsForm.controls['business'].touched)
                      ">
            <ng-container *ngFor="let error of detailsForm.controls['business'].errors | keyvalue">
              <ng-container *ngIf="error.key">
                <span>{{ error.value['message'] }}</span>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-error>
      </mat-form-field>

      <div class="flex flex-row gap-x-4 mt-[16px]">
        <ng-container *ngIf="!showRive; else proceed">
          <button mat-flat-button (click)="isValid()?saveDetails():showError()" [id]="'saveButton'">
            Save
          </button>
        </ng-container>

        <ng-template #proceed>
          <button mat-flat-button [id]="'buttondefaultButton'">
            <canvas riv="console-cta-button-loading-animation" width="28" height="28">
              <riv-player name="loading-1" play></riv-player>
            </canvas>
          </button>
        </ng-template>

        <button type="submit" mat-stroked-button routerLink="../" [id]="'cancelButton'">
          Cancel </button>
      </div>
    </form>
  </div>
</div>