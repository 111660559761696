import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { zip } from 'rxjs';
import { environment } from '../environments/environment';
import { HeaderComponent } from './components/common/header/header.component';
import { AuthService } from './services/auth.service';
import { ThemeService } from './services/theme.service';
import { ReferenceDataActions } from './store/reference-data/reference-data.actions';
import { UserActions } from './store/user/user.actions';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, StoreModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'my-account-app';
  loading = true;
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer, private themeService: ThemeService, private store: Store, private actions$: Actions, private authService: AuthService) {
    this.registerIcons();
  }
  ngOnInit(): void {

    this.themeService.initializeTheme();

    if (!environment.production) {
      this.authService.setToken(
        'eyJraWQiOiJKblwvdmhvSGhTZ29McjhGY1I1RG4xTGdTb3AzS3NHeVhhRkFYR3NiYjlxVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI4ZjViMGY4NS1lMTY5LTRmN2YtODg0OC02ZWJhYjdiZmM1ZGEiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX2hxMjBsdEtFMiIsImNsaWVudF9pZCI6IjIxbTdibDZtZG0ydW9tdjA0MWM2cGVtNWs2Iiwib3JpZ2luX2p0aSI6IjVlNmI2NDBkLWJmNDgtNGVlOC04OWY0LTlkMzU3MTkwNjEwYyIsImV2ZW50X2lkIjoiOTI2YWY3ZjAtZTE5MC00NTg0LTk0YTEtOTYwNDQ4ZmQyOGQ0IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTczNzQ1MTc2NSwiZXhwIjoxNzM3NTM4MTY1LCJpYXQiOjE3Mzc0NTE3NjUsImp0aSI6ImE1OGQyNWY0LTJiMDgtNGUwZS1hZjkzLWQ0NDE1NTY2N2JmZSIsInVzZXJuYW1lIjoiMTZkMTY1YWQtNjNkMi00N2Y5LWE2NWQtOTc3OTg4M2Y1ZWExIn0.hoK-Coef1JXDySQECPClGzaxzutVoxRD4Toz1H2eOHseds66NUopKPcMJHi1nCP4vFg6zzCUaFsuSujbLZjs6F9edEqZkCtVsE6uGWWrjs3RRVtTThZ39BuDhz_QFDUtg0vVZp8EL5r0Su9ViwBbU9BApVrAOoazwLp9iO5MKhGgag5Q79iGyN9Ql-8GLL-5_pNAKozLGDR0pgPCpo9ggFoogsgCtm9OgIeo8QQ-3pftKuZjVnOW-CN79BrqVh8zk85Z9f6ZbKBRw7SBPUfEe_HDRpr6cLyh64nZhUbMJ5u96V_O_u8uLhKztyBsjeQbqXJcdyJS43WWxwi1pesjlw'
      )
    }

    this.store.dispatch(ReferenceDataActions.GET_COUNTRIES());
    this.store.dispatch(UserActions.GET_USER())


    zip(this.actions$.pipe(ofType(ReferenceDataActions.GET_COUNTRIES_SUCCESS)),
      this.actions$.pipe(ofType(UserActions.GET_USER_SUCCESS))
    ).subscribe(([a, b]) => {
      this.loading = false;
    });



  }

  registerIcons() {
    this.iconRegistry.addSvgIcon('ic_sbox_brackets', this.sanitizer.bypassSecurityTrustResourceUrl(environment.ic_sbox_brackets))
  }

}
