import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { AvatarModule } from 'ngx-avatars';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Theme, ThemeService } from 'src/app/services/theme.service';
import { environment } from '../../../../environments/environment';
import { User } from '../../../beans/User';
import { UserSelectors } from '../../../store/user/user.selectors';

@Component({
  selector: 'sandbox-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  standalone: true,
  imports: [MatIconModule, FormsModule, MatSlideToggleModule, MatSlideToggle, MatDividerModule, MatButtonModule, MatMenuModule, CommonModule, AvatarModule, RouterModule],
})

export class HeaderComponent implements OnInit {
  user$: Observable<User> = this.store.select(UserSelectors.USER);
  destroy$: Subject<boolean> = new Subject<boolean>();
  isFreeTrial: boolean = false;
  theme$: Observable<Theme>;
  darkModeEnabled: boolean = false;

  constructor(private router: Router,
    private authService: AuthService,
    private themeService: ThemeService,
    private store: Store,
  ) {
  }

  ngOnInit() {
    this.theme$ = this.themeService.theme$;

    this.theme$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res == Theme.DARK) {
        this.darkModeEnabled = true
      }
    })
  }

  logout() {
    this.authService.deleteToken()
    window.location.href = environment.authentication_host + '/login' + '?url=' + btoa(window.location.href);
  }

  navigateToConsole() {
    window.location.href = environment.console_endpoint;
  }

  navigateToHome() {

    this.router.navigate([''])
  }

  changeTheme() {

    this.theme$.pipe(take(1)).subscribe((theme) => {
      if (theme === Theme.DARK)
        this.themeService.setTheme(Theme.LIGHT);
      else
        this.themeService.setTheme(Theme.DARK);

    })
  }
}
