<div class="flex flex-col mt-[32px] items-center bg-surface-container-lowest">
    <div class="flex flex-col w-[900px] items-center">
        <!-- Avatar Skeleton -->
        <ngx-skeleton-loader [theme]="{
          'border-radius': '50%',
          'width': '70px',
          'height': '70px',
          'margin': '0',
          'background-color': 'var(--sys-secondary-container)'
        }"></ngx-skeleton-loader>

        <!-- Welcome Text Skeleton -->
        <div class="flex flex-col mt-[8px] items-center">
            <ngx-skeleton-loader [theme]="{
            'height': '24px',
            'width': '200px',
            'margin-bottom': '0',
            'background-color': 'var(--sys-surface-container-low)'
          }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{
            'height': '16px',
            'width': '150px',
            'margin-top': '4px',
            'margin-bottom': '0',

            'background-color': 'var(--sys-surface-container-low)'
          }"></ngx-skeleton-loader>
        </div>
    </div>

    <!-- Personal Details Skeleton -->
    <div class="flex flex-col mt-[40px] w-[900px] ">
        <ngx-skeleton-loader [theme]="{
          'height': '20px',
          'width': '150px',
          'margin-bottom': '0',
          'background-color': 'var(--sys-surface-container-low)'
        }"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{
          'height': '16px',
          'width': '300px',
          'margin-top': '8px',
          'margin-bottom': '0',
          'background-color': 'var(--sys-surface-container-low)'
        }"></ngx-skeleton-loader>

        <ngx-skeleton-loader [theme]="{
          'height': '230px',
          'width': '100%',
          'margin-top': '16px',
          'margin-bottom': '0',
          'border-radius': '8px',
          'background-color': 'var(--sys-surface-container-low)'
        }"></ngx-skeleton-loader>
    </div>

    <!-- Login and Security Skeleton -->
    <div class="flex flex-col mt-[40px] w-[900px]">
        <ngx-skeleton-loader [theme]="{
          'height': '20px',
          'width': '200px',
          'margin-bottom': '0',
          'background-color': 'var(--sys-surface-container-low)'
        }"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{
          'height': '16px',
          'width': '300px',
          'margin-top': '8px',
          'background-color': 'var(--sys-surface-container-low)'
        }"></ngx-skeleton-loader>

        <div class="flex flex-row mt-[16px] gap-x-[16px]">
            <ngx-skeleton-loader [theme]="{
            'height': '150px',
            'width': '442px',
            'border-radius': '8px',
            'background-color': 'var(--sys-surface-container-low)'
          }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{
            'height': '150px',
            'width': '442px',
            'margin-bottom': '0',
            'border-radius': '8px',
            'background-color': 'var(--sys-surface-container-low)'
          }"></ngx-skeleton-loader>
        </div>
    </div>
</div>