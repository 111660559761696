<div class="flex flex-col  px-[24px] py-[24px]">
    <div class="flex flex-row justify-between w-full">
        <div class="flex flex-col">
            <span class="text-on-surface mat-title-medium">Verify email address</span>
            <span class="text-on-surface-variant mat-body-medium">Please enter the OTP sent to <span
                    class="text-on-surface mat-label-large">{{this.data.userEmail}}</span></span>
        </div>
        <button mat-icon-button mat-dialog-close class="mr-[-12px] mt-[-12px]" [id]="'closeButton'">
            <mat-icon class="material-symbols-rounded ">close </mat-icon>
        </button>
    </div>
    <div class="flex flex-col mt-[32px] w-full">
        <ng-otp-input (onInputChange)="onOtpChange($event)" (keydown.enter)="verify()" [config]="{length:6, allowNumbersOnly: true, inputStyles: {
            'font-size': '20px',
            'font-weight': '500',
            'width': '46px',
            'height': '56px',
            'background-color': 'var(--sys-surface-container-lowest)',
            'color': 'var(--sys-on-surface)'
            }}">
        </ng-otp-input>
    </div>
    <div class=" flex items-center h-[20px] mt-4">
        <button *ngIf="timeLeft != 0" [disabled]="timeLeft != 30" (click)="generateOTP()"
            [ngClass]="timeLeft == 0 ? 'text-primary' : 'text-on-surface-variant'" class="mat-label-large  mt-4">Resend
            OTP<span *ngIf="timeLeft != 30 && timeLeft != 0"> (Available in {{transform(timeLeft)}})</span>
        </button>
        <button *ngIf="timeLeft == 0" (click)="generateOTP()" class=" mt-4 text-primary">
            Resend OTP
        </button>
    </div>

    <div class="flex gap-x-4 mt-[32px] w-full">

        <ng-container *ngIf="!showRive; else proceed">
            <button (click)="verify()" mat-flat-button [id]="'verifyButton'">
                Verify
            </button>
        </ng-container>

        <ng-template #proceed>
            <button mat-flat-button [id]="'buttondefaultButton'">
                <canvas riv="console-cta-button-loading-animation" width="28" height="28">
                    <riv-player name="loading-1" play></riv-player>
                </canvas>
            </button>
        </ng-template>

        <button mat-stroked-button mat-dialog-close [id]="'backButton'">
            Back
        </button>

    </div>

</div>