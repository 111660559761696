<div class="flex flex-col mt-[32px] items-center mb-[134px]">
  <div class="flex flex-col w-[603px]">
    <div class=" flex mb-4">
      <div class="flex gap-1 items-center justify-center">
        <p class=" text-on-surface cursor-pointer  mat-label-large" (click)="goBack()">
          My Account</p>
        <mat-icon class="material-symbols-rounded text-on-surface-variant">chevron_right</mat-icon>
        <p class=" text-on-surface-variant mat-label-large">Email address</p>
      </div>
    </div>
    <div class="flex flex-col gap-y-2 ">
      <span class="mat-title-large text-on-surface">Email address</span>
      <span class=" mat-body-medium text-on-surface-variant">Change your registered email address.</span>
    </div>
    <form [formGroup]="emailForm" class="mt-10">
      <mat-form-field appearance="outline" class=" w-[600px] ">
        <mat-label>Email Address</mat-label>
        <input matInput formControlName="email" type="email" placeholder="Email Address">
        <mat-error>
          <ng-container *ngIf="
                          emailForm.controls['email'].errors &&
                          (emailForm.controls['email'].dirty || emailForm.controls['email'].touched)
                        ">
            <ng-container *ngFor="let error of emailForm.controls['email'].errors | keyvalue">
              <ng-container *ngIf="error.key">
                <span>{{ error.value['message'] }}</span>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-error>
      </mat-form-field>
      <div class="flex flex-row mt-[16px] gap-x-4">
        <ng-container *ngIf="!proceedToVerify; else proceed">
          <button (click)=" isValid() ? updateEmail():showError()" mat-flat-button [id]="'proceedButton'">
            Proceed
          </button>
        </ng-container>
        <ng-template #proceed>
          <button mat-flat-button class="max-w-[110px]" [id]="'buttondefaultButton'">
            <canvas riv="console-cta-button-loading-animation" width="28" height="28">
              <riv-player name="loading-1" play></riv-player>
            </canvas>
          </button>
        </ng-template>

        <button type="submit" mat-stroked-button routerLink="../" [id]="'cancelButton'">
          Cancel </button>
      </div>
    </form>
  </div>
</div>