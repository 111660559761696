<div class="flex flex-col mt-[32px] items-center mb-[134px]">
  <div class="flex flex-col w-[603px]">

    <div class=" flex mb-4">
      <div class="flex gap-1 items-center justify-center">
        <p class=" text-on-surface cursor-pointer  mat-label-large" (click)="goBack()">
          My Account</p>
        <mat-icon class="material-symbols-rounded text-on-surface-variant">chevron_right</mat-icon>
        <p class=" text-on-surface-variant mat-label-large">Password</p>
      </div>
    </div>

    <div class="flex flex-col gap-y-2  mb-[40px]">
      <span class="mat-title-large text-on-surface">Set Password</span>
      <span class=" mat-body-medium text-on-surface-variant">Set a strong password to secure your account</span>
    </div>
    <form [formGroup]="passwordForm">
      <div class="flex flex-col">
        <mat-form-field appearance="outline" class=" w-[600px] ">
          <mat-label>New Password</mat-label>
          <input matInput formControlName="new_password" type="password" [type]="showNewPassword ? 'text' : 'password'"
            onpaste="return false" ondrop="return false" autocomplete="off" placeholder="New Password">

          <mat-icon matSuffix class="material-symbols-rounded " (click)="showNewPassword=false" *ngIf="showNewPassword">
            visibility_off</mat-icon>
          <mat-icon matSuffix class="material-symbols-rounded " (click)="showNewPassword=true" *ngIf="!showNewPassword">
            visibility</mat-icon>

          <mat-error>
            <ng-container
              *ngIf="passwordForm.controls['new_password'].errors && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)">
              <ng-container *ngFor="let error of passwordForm.controls['new_password'].errors | keyvalue">
                <ng-container *ngIf="error.key">
                  <span>{{ error.value['message'] }}</span>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>

        <div class="flex flex-col gap-y-3 mt-3">
          <div class="flex gap-x-2">
            <mat-icon [ngClass]="isLengthValid ? 'text-primary' : 'text-outline-variant'"
              class=" material-symbols-rounded">
              check_circle
            </mat-icon>
            <p class="mat-body-medium text-on-surface">Must be at least 8 characters long</p>
          </div>

          <div class="flex flex-row gap-2">
            <mat-icon [ngClass]="isLowercaseValid ? 'text-primary' : 'text-outline-variant'"
              class=" material-symbols-rounded">
              check_circle
            </mat-icon>
            <p class="mat-body-medium text-on-surface">Must contain at least one lowercase character</p>
          </div>

          <div class="flex flex-row gap-2">
            <mat-icon [ngClass]="isUppercaseValid ? 'text-primary' : 'text-outline-variant'"
              class=" material-symbols-rounded">
              check_circle
            </mat-icon>
            <p class="mat-body-medium text-on-surface">Must contain at least one uppercase character</p>
          </div>

          <div class="flex flex-row gap-2">
            <mat-icon [ngClass]="isNumberValid ? 'text-primary' : 'text-outline-variant'"
              class=" material-symbols-rounded">
              check_circle
            </mat-icon>
            <p class="mat-body-medium text-on-surface">Must contain at least one number</p>
          </div>

          <div class="flex flex-row gap-2">
            <mat-icon [ngClass]="isSpecialCharacterValid ? 'text-primary' : 'text-outline-variant'"
              class=" material-symbols-rounded">
              check_circle
            </mat-icon>
            <p class="mat-body-medium text-on-surface">Must include at least one special character</p>
          </div>
        </div>

      </div>

      <mat-form-field appearance="outline" class="w-[600px]  mt-8">
        <mat-label>Confirm Password</mat-label>
        <input matInput formControlName="confirm_password" type="password"
          [type]="showConfirmPassword ? 'text' : 'password'" onpaste="return false" ondrop="return false"
          autocomplete="off" placeholder="Confirm Password">

        <mat-icon matSuffix class="material-symbols-rounded " (click)="showConfirmPassword=false"
          *ngIf="showConfirmPassword">
          visibility_off</mat-icon>
        <mat-icon matSuffix class="material-symbols-rounded " (click)="showConfirmPassword=true"
          *ngIf="!showConfirmPassword">
          visibility</mat-icon>

        <mat-error>
          <ng-container *ngIf="
                                      passwordForm.controls['confirm_password'].errors &&
                                      (passwordForm.controls['confirm_password'].dirty || passwordForm.controls['confirm_password'].touched)
                                    ">
            <ng-container *ngFor="let error of passwordForm.controls['confirm_password'].errors | keyvalue">
              <ng-container *ngIf="error.key">
                <span>{{ error.value['message'] }}</span>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-error>

      </mat-form-field>

      <div class="flex mt-[16px] gap-x-4">
        <ng-container *ngIf="!showRive; else proceed">
          <button (click)="isValid()? setPassword():showError()" mat-flat-button [id]="'saveButton'">
            Save
          </button>
        </ng-container>
        <ng-template #proceed>
          <button mat-flat-button [id]="'buttondefaultButton'">
            <canvas riv="console-cta-button-loading-animation" width="28" height="28">
              <riv-player name="loading-1" play></riv-player>
            </canvas>
          </button>
        </ng-template>
        <button type="submit" mat-stroked-button routerLink="../" [id]="'cancelButton'">
          Cancel </button>
      </div>
    </form>
  </div>
</div>