import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { zip } from 'rxjs';
import { environment } from '../environments/environment';
import { HeaderComponent } from './components/common/header/header.component';
import { AuthService } from './services/auth.service';
import { ThemeService } from './services/theme.service';
import { ReferenceDataActions } from './store/reference-data/reference-data.actions';
import { UserActions } from './store/user/user.actions';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, StoreModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'my-account-app';
  loading = true;
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer, private themeService: ThemeService, private store: Store, private actions$: Actions, private authService: AuthService) {
    this.registerIcons();
  }
  ngOnInit(): void {

    this.themeService.initializeTheme();

    if (!environment.production) {
      this.authService.setToken(
        'eyJraWQiOiJKblwvdmhvSGhTZ29McjhGY1I1RG4xTGdTb3AzS3NHeVhhRkFYR3NiYjlxVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI5OGFkZjliZC0yZmM1LTQ5NDgtYTA5MC1jY2Q0Y2IxZGZkYmIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX2hxMjBsdEtFMiIsInZlcnNpb24iOjIsImNsaWVudF9pZCI6IjIxbTdibDZtZG0ydW9tdjA0MWM2cGVtNWs2Iiwib3JpZ2luX2p0aSI6IjcyNjE2ZWU4LTcwOTctNDNmYi1iYzNiLWEyYzI0OTMwNWYyMSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4gb3BlbmlkIHByb2ZpbGUgZW1haWwiLCJhdXRoX3RpbWUiOjE3Mzc5NTYwNzIsImV4cCI6MTczODA0MjQ3MiwiaWF0IjoxNzM3OTU2MDcyLCJqdGkiOiJiMzNhMmYwZi02NDg5LTRiZGMtYTA2OC1mYWEyNDhjM2IwMTAiLCJ1c2VybmFtZSI6IjAyYTM3N2E0LThkYTItNDcyOC1hNGE5LWMzNzhkMWY5YTFjOSJ9.bFefjUFiokSZxqG4Zw6DFAv3jdg-qkkXo3pnZd9CZT0x0fThMwlW8_uY6SwWc6Lea4qdvIvtpTiSgWIJeNEq_Cr2WJSzkhomYGUn1uHyPNtSYhfMdn4IpBJC1Dmewe_4X_q--i-xqqnlisiyhHAUpPyKpPWR_48VmiOMMn2y-vT7RkgwziAUhwlDRksS45T0r6YG5N92krnLMo0QOmWMmX0BHSvKC7-JFCXlD3-QmfZL7R4J0cDqktrnwlED8tZfrE7BN_D7J4Uq2WKQInWQh8PnGuiaROuOckB6N3hWoFrFeXgJvJv2Ofp_syutSsjaNrjquV37Tc7xOk8j7mzWpw'
      )
    }

    this.store.dispatch(ReferenceDataActions.GET_COUNTRIES());
    this.store.dispatch(UserActions.GET_USER())


    zip(this.actions$.pipe(ofType(ReferenceDataActions.GET_COUNTRIES_SUCCESS)),
      this.actions$.pipe(ofType(UserActions.GET_USER_SUCCESS))
    ).subscribe(([a, b]) => {
      this.loading = false;
    });



  }

  registerIcons() {
    this.iconRegistry.addSvgIcon('ic_sbox_brackets', this.sanitizer.bypassSecurityTrustResourceUrl(environment.ic_sbox_brackets))
  }

}
