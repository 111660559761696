export const environment = {
  production: true,
  profile: 'dev',

  sandbox_account_logo: '/assets/icons/sandbox_account_logo.svg',

  authentication_host: 'http://dev1-accounts.sandbox.co.in',

  org_quicko_cms_endpoint: 'https://d2l1ae64bvxp7e.cloudfront.net',
  api_auth_endpoint: 'https://keq7mx11sf.execute-api.ap-south-1.amazonaws.com/dev/auth',
  console_endpoint: "https://dev-console.sandbox.co.in",

  ic_sbox_brackets: 'assets/icons/ic_sbox_brackets.svg',

};
