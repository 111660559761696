import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NgOtpInputModule } from 'ng-otp-input';
import { RiveModule } from 'ng-rive';
import { Subject, Subscription, take, takeUntil, timer } from 'rxjs';
import { User } from '../../beans/User';
import { EntitlementsService } from '../../services/entitlements.service';
import { SnackbarService } from '../../services/snackbar.service';
import { UserActions } from '../../store/user/user.actions';

@Component({
  selector: 'app-verify-email-dialog',
  templateUrl: './verify-email-dialog.component.html',
  styleUrls: ['./verify-email-dialog.component.css'],
  standalone: true,
  imports: [NgOtpInputModule, CommonModule, MatDialogModule, RouterModule, MatButtonModule, RiveModule, MatIconModule]
})
export class VerifyEmailDialogComponent implements OnInit {
  timeLeft = 30;
  destroy$: Subject<void> = new Subject<void>();
  countDown: Subscription | undefined;
  routeParams: any;
  otp: string | undefined;
  tick = 1000;
  user: User | undefined;
  showRive: boolean = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private actions$: Actions, private entitlementService: EntitlementsService, private dialog: MatDialog, private snackbarService: SnackbarService, private store: Store, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.startTimer();
  }

  startTimer() {
    this.countDown = timer(0, this.tick)
      .pipe(take(this.timeLeft))
      .subscribe(() => {
        --this.timeLeft;
        if (this.timeLeft == 0) {
          this.countDown?.unsubscribe();
        }
      });
  }

  onOtpChange(otp: string) {
    if (otp.length === 6) {
      this.otp = otp;
    } else {
      this.otp = undefined
    }
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

  generateOTP() {
    this.user = new User();
    this.user.email = (this.data.userEmail)

    this.store.dispatch(UserActions.RESEND_OTP({ user: this.user }))

    this.actions$.pipe(ofType(UserActions.RESEND_OTP_SUCCESS), takeUntil(this.destroy$)).subscribe(() => {
      this.snackbarService.openSnackBar('OTP sent successfully', undefined);
      this.timeLeft = 30;
      this.startTimer();
    })

    this.actions$.pipe(ofType(UserActions.RESEND_OTP_FAILED), takeUntil(this.destroy$)).subscribe(() => {
      this.snackbarService.openSnackBar('Failed to send OTP', undefined)

    })
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions to prevent memory leaks
    this.destroy$.next();
    this.destroy$.complete();
  }


  verify() {
    if (this.otp != undefined) {
      this.showRive = true;
      this.store.dispatch(UserActions.VERIFY_EMAIL({ code: this.otp }))

      this.actions$.pipe(ofType(UserActions.VERIFY_EMAIL_FAILED), takeUntil(this.destroy$)).subscribe(() => {
        this.showRive = false;
      })
      this.actions$.pipe(ofType(UserActions.VERIFY_EMAIL_SUCCESS), takeUntil(this.destroy$)).subscribe(() => {
        this.snackbarService.openSnackBar('Email updated successfully', undefined)
        this.showRive = false;
        this.router.navigate(['../'], {
          relativeTo: this.route,
        });
        this.store.dispatch(UserActions.GET_USER());
        this.dialog.closeAll();
      })
    } else {
      this.snackbarService.openSnackBar('Please Enter Valid OTP', undefined);
    }
  }

}
