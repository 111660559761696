<ng-container *ngIf="loading; else content">
  <app-my-account-skeleton-loading />
</ng-container>

<ng-template #content>
  <div class="flex flex-col mt-[32px] items-center  bg-surface-container-lowest">
    <div class="flex flex-col w-[900px] items-center">
      <div class="flex flex-col items-center">
        <ngx-avatars bgColor="var(--sys-secondary-container)" fgColor="var(--sys-on-secondary-container)" size="70"
          [textSizeRatio]="2" name="{{ (user$ | async)?.firstName[0] | titlecase }}">
        </ngx-avatars>

        <div class="flex flex-col mt-[16px] items-center">
          <span class="mat-title-large text-on-surface">Welcome, {{ this.user?.firstName }} {{ this.user?.lastName
            }}</span>
          <span class="mt-[4px] mat-body-medium text-on-surface-variant">Joined on {{ this.user?.createdAt | dateFormat
            }}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="flex flex-col mt-[40px]">
        <span class="mat-title-medium text-on-surface">Personal details</span>
        <span class="mt-[8px] text-on-surface-variant mat-body-medium">Manage your personal identity across
          sandbox</span>
      </div>
      <mat-card appearance="outlined"
        class="flex flex-col border rounded-lg border-solid border-outline-variant w-[900px] mt-[16px] ">
        <div class="flex flex-col w-[900px] py-[8px]">
          <mat-card-content class="flex flex-row px-[24px] py-[16px]">
            <span class="text-on-surface-variant mat-body-large">Full name</span>
            <span class="text-on-surface mat-title-medium ml-[195px]">{{ this.user?.firstName }} {{
              this.user?.lastName }}</span>
          </mat-card-content>
          <mat-card-content class="flex flex-row px-[24px] py-[16px]">
            <span class="text-on-surface-variant mat-body-large">Phone number</span>
            <ng-container *ngIf="this.user?.phone; else notAvailable">
              <span class="text-on-surface mat-title-medium ml-[156px]">
                {{this.user?.phone.slice(0,3)}} {{formatPhoneNumber(this.user?.phone)}}</span>
            </ng-container>
            <ng-template #notAvailable>
              <span class="text-on-surface-variant mat-body-large ml-[156px]">
                Not available</span>
            </ng-template>
          </mat-card-content>
          <mat-card-content class="flex flex-row px-[24px] py-[16px]">
            <span class="text-on-surface-variant mat-body-large">Business name</span>
            <span *ngIf="this.user?.business" class="text-on-surface mat-title-medium ml-[152px]">{{
              this.user?.business
              }}</span>
          </mat-card-content>
        </div>
        <mat-divider class="text-outline-variant"></mat-divider>
        <mat-card-actions class="flex flex-row hover:bg-surface-container-low hover:rounded-[6px] hover:rounded-t-none"
          matRipple>
          <button routerLink="./details">
            <span class="text-primary mat-label-large pr-[745px] px-[16px] py-[16px]">Change Details</span>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-col gap-y-2 mt-[40px]">
        <span class="mat-title-medium text-on-surface">Login and security</span>
        <span class=" mat-body-medium text-on-surface-variant">Manage your login credentials used to access
          Sandbox</span>
      </div>
      <div class="flex flex-row mt-[16px]">
        <mat-card appearance="outlined"
          class="flex flex-col border rounded-lg border-solid border-outline-variant w-[442px]">
          <mat-card-content class="flex flex-col py-[16px] px-[24px] w-full">
            <mat-icon class="material-symbols-rounded text-primary h-[32px] w-[32px] ">email</mat-icon>
            <span class="flex flex-row text-on-surface-variant mat-body-large mt-[16px]">Email Address</span>
            <div class="overflow-hidden text-ellipsis flex-shrink-0 whitespace-nowrap max-w-[390px] mt-[8px] ">
              <span class="text-on-surface mat-title-medium  ">
                {{ this.user?.email }}
              </span>
            </div>
          </mat-card-content>

          <mat-divider class="text-outline-variant"></mat-divider>

          <mat-card-actions class="flex flex-row hover:bg-surface-container-low" matRipple>
            <button routerLink="./email">
              <span class="text-primary mat-label-large py-[16px] pl-[16px] pr-[300px]">Change Email</span>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card appearance="outlined"
          class="flex flex-col ml-[16px] border rounded-lg border-solid text-outline-variant w-[442px] mat-elevation-z0">
          <mat-card-content class="flex flex-col py-[16px] px-[24px]">
            <mat-icon class="material-symbols-rounded text-primary h-[32px] w-[32px] my-icon">password</mat-icon>
            <span class="flex flex-row text-on-surface-variant mat-body-large mt-[16px]">Password</span>
            <span class="flex flex-row mat-title-medium mt-[8px] text-on-surface">{{ passwordMessage }}</span>
          </mat-card-content>

          <mat-divider class="text-outline-variant"></mat-divider>

          <mat-card-actions class="flex flex-row hover:bg-surface-container-low" matRipple>
            <button *ngIf="this.user?.isPasswordSet" routerLink="./password">
              <span class="text-primary mat-label-large py-[16px] pl-[16px] pr-[280px]">Change Password</span>
            </button>
            <button *ngIf="!this.user?.isPasswordSet" routerLink="./set-password">
              <span class="text-primary mat-label-large py-[16px] pl-[16px] pr-[280px]">Set Password</span>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>

</ng-template>