import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { User } from '../../beans/User';
import { SnackbarService } from '../../services/snackbar.service';
import { UserActions } from '../../store/user/user.actions';
import { UserSelectors } from '../../store/user/user.selectors';
import { VerifyEmailDialogComponent } from '../verify-email-dialog/verify-email-dialog.component';

@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.css'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatIconModule, CommonModule, RiveModule, ReactiveFormsModule, RouterModule, MatButtonModule]
})
export class EditEmailComponent implements OnInit {
  destroy$: Subject<void> = new Subject<void>();
  user$: Observable<User> = this.store.select(UserSelectors.USER);
  user: User | undefined;
  emailForm: FormGroup;
  loading: boolean = true;
  proceedToVerify: boolean = false;
  sameEmail: boolean = true;

  constructor(private store: Store, private router: Router, private actions$: Actions, public dialog: MatDialog, private snackbarService: SnackbarService) {
    this.emailForm = new RxFormBuilder().formGroup(new User())
  }

  ngOnInit() {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: User) => {
      if (user) {
        this.user = user;

        if (!this.emailForm.controls['email'].valid)
          this.emailForm.patchValue(user)
      }
    });
  }

  updateEmail() {
    if (this.user?.email != this.emailForm.controls['email'].value) {
      this.proceedToVerify = true;
      const newuser = new User();
      newuser.email = this.emailForm.controls['email'].value.toString();
      this.store.dispatch(UserActions.PATCH_USER({ user: newuser }))

      this.actions$.pipe(ofType(UserActions.PATCH_USER_SUCCESS), takeUntil(this.destroy$)).subscribe(() => {
        const userEmail = newuser.email;
        this.proceedToVerify = false;
        const dialogRef = this.dialog.open(VerifyEmailDialogComponent, {
          data: { userEmail },
          width: '600px',
          autoFocus: false
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
          this.dialog.closeAll();
        })
      });


      this.actions$.pipe(ofType(UserActions.PATCH_USER_FAILED), takeUntil(this.destroy$)).subscribe(() => {
        this.proceedToVerify = false;
      });
    }
    else {
      this.snackbarService.openSnackBar("Please enter new email address", undefined);
    }

  }

  closeDialog() {

  }

  isValid() {
    return this.emailForm.controls['email'].valid
  }

  showError() {
    this.emailForm.markAllAsTouched();
  }

  goBack() {
    this.router.navigate([''])
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
